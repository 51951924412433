import { Column, useTable } from 'react-table'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table } from 'react-bootstrap'

export const InfiniteTable = <O extends object>({
  columns,
  data,
  loadMoreRows,
  hasMore,
}: {
  columns: ReadonlyArray<Column<O>>
  data: O[]
  loadMoreRows: () => void
  hasMore: boolean
}) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={loadMoreRows}
      hasMore={hasMore}
      loader={<span />}
    >
      <Table striped hover {...getTableProps()}>
        <thead>
          <tr>
            {headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </InfiniteScroll>
  )
}
